<template>
	<section id="about" class="py-15">
		<v-container fluid>
			<p class="text-h4 text-center font-weight-bold text-uppercase mb-2">
				¿Qué es OverSOS?
			</p>
			<p class="text-h6 text-center mx-auto" style="max-width: 800px">
				Somos una plataforma que busca unir al estudiante con el mundo laboral y ser el enlace directo con la primera experiencia profesional.
			</p>
			<v-card max-width="1200" class="mt-15 mx-auto d-flex flex-wrap justify-space-around" flat tile color="transparent">
				<v-card color="transparent" class="mt-3 text-center" max-width="300" flat tile>
					<v-icon size="48">mdi-school</v-icon>
					<v-card-title>
						<span class="mx-auto">¿Qué encontrarás?</span>
					</v-card-title>
					<!-- <v-card-subtitle>
						<span class="mx-auto">Comunidad cooperativa</span>
					</v-card-subtitle> -->
					<v-card-text class="text-justify">
						<p>
							Tenemos un perfil de estudiante con ganas de incorporarse en el mercado laboral. Estamos presentes en varias escuelas de ingenería en
							Barcelona, especialmente en la escuela de Ingeniería Industrial de la UPC en Barcelona (ETSEIB).
						</p>
					</v-card-text>
				</v-card>
				<v-card color="transparent" class="mt-3 text-center" max-width="300" flat tile>
					<v-icon size="48">mdi-briefcase</v-icon>
					<v-card-title>
						<span class="mx-auto">Estudiantes preparados</span>
					</v-card-title>
					<!-- <v-card-subtitle>
						<span class="mx-auto">Conectar empresas con estudiantes</span>
					</v-card-subtitle> -->
					<v-card-text class="text-justify">
						<p>
							Aprovechamos que los estudiantes están registrados en nuestra plataforma para brindarles la oportunidad de tener una primera toma de
							contacto con las empresas de manera fácil y rápida.
						</p>
					</v-card-text>
				</v-card>
				<v-card color="transparent" class="mt-3 text-center" max-width="300" flat tile>
					<v-icon size="48">mdi-web</v-icon>
					<v-card-title>
						<span class="mx-auto">Amplio espectro</span>
					</v-card-title>
					<!-- <v-card-subtitle>
						<span class="mx-auto">Networking con empresas</span>
					</v-card-subtitle> -->
					<v-card-text class="text-justify">
						<p>
							Centenares de estudiantes terminan la universidad cada año o van en búsqueda de su primera experiencia laboral. OverSOS ofrece a sus
							clientes la posibilidad de encontrar a la mejor opción para cada puesto.
						</p>
					</v-card-text>
				</v-card>
			</v-card>
		</v-container>
	</section>
</template>

<script>
export default {
	name: 'LandingAbout'
}
</script>
